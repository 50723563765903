"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";
import { PriceConverter } from "@/app/utils/priceConverter";
import { PricingCardProps } from "./PricingCard";

export default function PricingCardText(props: PricingCardProps) {
    const price: number = PriceConverter.centsToUnits(props.package.price);
    const { t } = useTranslation(Translations.HOME);

    return (
        <section className="flex flex-col gap-8">
            <section className="flex flex-col gap-2">
                <section className="flex flex-row justify-between gap-4">
                    <span
                        className={
                            `font-semibold text-neutral-950 
                            ${!props.package.popular && 
                            "dark:text-neutral-50"}`
                        }
                    >
                        {t(`${Translations.HOME}:pricing.card.title`, {
                            creditsNumber: props.package.credits
                        })}
                    </span>
                    {props.package.popular && (
                        <span
                            className={
                                `rounded-xl py-1 px-2 border text-xs 
                                text-neutral-700 border-neutral-700 
                                ${!props.package.popular && 
                                `dark:border-neutral-200 
                                dark:text-neutral-200`}`
                            }
                        >
                            {t(
                                `${
                                    Translations.HOME
                                }:pricing.card.popular`
                            )}
                        </span>
                    )}
                </section>
            </section>
            <section className="flex flex-row gap-2 items-end">
                <h3
                    className={
                        `tracking-tighter font-bold text-6xl 
                        text-neutral-950
                        ${!props.package.popular && 
                        "dark:text-neutral-50"}`
                    }
                >
                    {t(`${Translations.HOME}:pricing.card.price`, {
                        price: price
                    })}
                </h3>
                <span
                    className={
                        `text-sm text-neutral-950 
                        ${!props.package.popular && 
                        "dark:text-neutral-50"}`
                    }
                >
                    {t(`${Translations.HOME}:pricing.card.recurring`)}
                </span>
            </section>
        </section>
    );
}

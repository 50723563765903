"use client";

import { ReactNode } from "react";
import { motion } from "framer-motion";

export default function FadeInView({ children }: { children: ReactNode }) {
    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 1 }}
            variants={{
                visible: { opacity: 1, y: 0 },
                hidden: { opacity: 0, y: -50 },
            }}
        >
            {children}
        </motion.div>
    );
}

export enum TaskStatus {
    Pending = "pending",
    Completed = "completed",
    Failed = "failed",
    None = "",
};

export interface RawTaskParams {
    base64: string;
    taskId: string;
};

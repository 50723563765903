"use client";

import { useTranslation } from "react-i18next";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import Translations from "@/app/constants/translations";
import { useState } from "react";

interface ImageFooterProps {
    submitFiles: () => Promise<void>;
    userCredits: number;
    numberFiles: number;
};

export default function ImageFooter(props: ImageFooterProps) {
    const { t } = useTranslation(Translations.HOME);
    const [disabled, setDisabled] = useState<boolean>(
        props.numberFiles > props.userCredits ||
        props.numberFiles <= 0
    );

    return (
        <section
            className="flex flex-row justify-center w-full h-fit gap-4 
            bg-neutral-100 dark:bg-neutral-900"
        >
            <button
                disabled={disabled}
                onClick={async () => {
                    if (disabled) return;
                    setDisabled(true);
                    await props.submitFiles();
                    setDisabled(false);
                }}
                className={
                    `flex flex-row justify-center align-center w-full 
                    max-w-96 gap-4 px-6 py-3 rounded-full bg-gradient-to-br 
                    from-amber-400 to-amber-500 backdrop-blur-sm shadow-lg 
                    text-center truncate lg:px-8 lg:py-3 transition-all 
                    ease-in-out duration-300
                    ${disabled ? "filter grayscale opacity-50" : 
                    "hover:brightness-110 dark:hover:brightness-90"}`
                }
            >
                <section className="flex flex-row gap-2">
                    <CurrencyDollarIcon
                        className="w-6 h-6 rounded-full bg-gradient-to-br
                        from-amber-400 to-amber-600 text-amber-300
                        dark:text-amber-300"
                    />
                    <span 
                        className="font-bold text-neutral-950 
                        dark:text-neutral-50"
                    >
                        {props.numberFiles}
                    </span>
                </section>
                <hr
                    className="border-s h-full border-amber-300 
                    dark:border-amber-300"
                />
                <span
                    className="font-bold text-neutral-950 
                    dark:text-neutral-50 truncate"
                >
                    {t(`${Translations.HOME}:generate.button.transform`, {
                        count: props.numberFiles
                    })}
                </span>
            </button>
        </section>
    );
}

"use client";

import { Trans, useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";
import HeroSectionTextAnimated from "./HeroSectionTextAnimated";

export default function HeroSectionText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <section 
            className="flex flex-col gap-10 text-center lg:gap-16 
            lg:text-start"
        >
            <h1
                className="flex flex-col gap-3 font-bold text-4xl 
                text-neutral-950 md:-mb-4 md:text-5xl lg:leading-tight 
                lg:text-6xl dark:text-neutral-50"
            >
                <Trans
                    i18nKey={`${Translations.HOME}:hero.title`}
                    components={{ 
                        animatedWord: <HeroSectionTextAnimated />
                    }}
                    className="inline-flex"
                />
            </h1>
            <p 
                className="leading-relaxed text-balance text-base 
                text-neutral-700 md:text-lg lg:text-lg 
                dark:text-neutral-200"
            >
                {t(`${Translations.HOME}:hero.description`)}
            </p>
        </section>
    );
}

"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";
import { PricingCardProps } from "./PricingCard";

interface PricingCardButtonTextProps extends PricingCardProps {
    authenticated: boolean;
};

export default function PricingCardButtonText(props: PricingCardButtonTextProps) {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span 
            className={
                `font-semibold text-xl text-neutral-50
                ${props.package.popular && 
                "text-neutral-950 dark:text-neutral-50"}`
            }
        >
            {t(
                props.authenticated ? 
                `${Translations.HOME}:pricing.button-authenticated` :
                `${Translations.HOME}:pricing.button`
            )}
        </span>
    );
}

export class PriceConverter {

    static centsToUnits(cents: number): number {
        return parseFloat((cents / 100).toFixed(2));
    }

    static unitsToCents(units: number): number {
        return Math.round(units * 100);
    }
}

"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function HeroSectionButtonSubtitle() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span 
            className="text-center text-xs text-neutral-700 
            dark:text-neutral-200"
        >
            {t(`${Translations.HOME}:hero.button.subtitle`)}
        </span>
    );
}

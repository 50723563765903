export default class FileConversion {

    static async toFile(base64: string): Promise<File> {
        const response: Response = await fetch(base64);
        const blob: Blob = await response.blob();
        return new File([blob], "file");
    }

    static async toBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader: FileReader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(file);
        });
    }
}

import { TaskStatus, RawTaskParams } from "@/app/@types/TaskFile";
import TaskStatusType from "@/app/constants/taskStatus";
import SavedManager from "@/app/lib/savedManager";
import FileConversion from "@/app/utils/fileConversion";
import { RandomManager } from "@/app/utils/randomManager";

export interface InputFile {
    file: File | string;
    status: TaskStatus;
    taskId: string;
    date: number;
};

export class FileManager {
    private _files: InputFile[] = [];
    private _index: number = 0;

    constructor(files: InputFile[] = []) {
        this._files = files;
    }

    get index(): number {
        return this._index;
    }

    set index(index: number) {
        this._index = index;
    }

    taskIds(reload: boolean): string[] {
        return this._files
            .filter(file => reload ? 
                TaskStatusType.isUploaded(file.status) : 
                TaskStatusType.isQueued(file.status)
            )
            .map(file => file.taskId);
    }

    get files(): InputFile[] {
        return this._files;
    }

    get length(): number {
        return this.files.length;
    }

    lengthNotUploaded(): number {
        return this.files
            .filter(file => TaskStatusType.isNotUploaded(file.status))
            .length;
    }

    empty(): boolean {
        return this.length === 0;
    }

    byTaskId(taskId: string): InputFile | undefined {
        return this._files.find(file => file.taskId === taskId);
    }

    private toInputFile(file: File): InputFile {
        return {
            file: file, // Raw file data.
            status: TaskStatus.None, // Default status.
            taskId: RandomManager.uuid(), // Uuid from server.
            date: new Date().getTime(),
        }
    }

    add(files: File[]): void {
        const savedManager: SavedManager = new SavedManager();
        const inputFiles: InputFile[] = files.map(this.toInputFile);
        inputFiles.forEach(async (file) => await savedManager.add(file));
        this._files.push(...inputFiles);
    }

    remove(index: number): void {
        const file: InputFile | undefined = this._files.at(index);
        const savedManager: SavedManager = new SavedManager();
        if (file) savedManager.remove(file.taskId);
        this._files = this._files.filter((_, i) => i !== index);
    }

    async replace(file: InputFile): Promise<void> {
        const index = this._files.findIndex(
            currentFile => currentFile.taskId === file.taskId
        );
        if (index !== -1) this._files[index] = file;
        await new SavedManager().add(file);
    }

    async replaceTaskId(taskId: string, newTaskId: string): Promise<void> {
        const file: InputFile | undefined = this.byTaskId(taskId);
        if (!file) return;
        file.taskId = newTaskId;
        await this.replace(file);
        await new SavedManager().remove(taskId);
    }

    async getFilesToUpload(): Promise<RawTaskParams[]> {
        const outputFiles: RawTaskParams[] = await Promise.all(this.files
            // Do not output files that are already uploaded/edited.
            .filter(file => !TaskStatusType.isUploaded(file.status))
            .map(async (file) => {
                const base64: string = await (
                    file.file instanceof File ?
                    FileConversion.toBase64(file.file) :
                    file.file
                );
                return {
                    base64: base64,
                    taskId: file.taskId,
                }
            })
        );
    
        const savedManager: SavedManager = new SavedManager();
        this._files = await Promise.all(this._files.map(async (file) => {
            if (!TaskStatusType.isUploaded(file.status)) {
                file.status = TaskStatus.Pending;
                await savedManager.add(file);
            }
            return file;
        }));
    
        return outputFiles;
    }
}

import TaskStatusType from "../constants/taskStatus";
import FileConversion from "../utils/fileConversion";
import { FileManager, InputFile } from "./fileManager";

export default class SavedManager {
    private static readonly DB_NAME: string = "ImageDatabase";
    private static readonly STORE_NAME: string = "images";

    constructor() {
        this._initializeDB();
    }

    private async _initializeDB(): Promise<void> {
        if (typeof indexedDB === "undefined") return;

        const request = indexedDB.open(SavedManager.DB_NAME, 1);
        request.onupgradeneeded = (event: any) => {
            const db = event.target.result;
            db.createObjectStore(
                SavedManager.STORE_NAME, { keyPath: "taskId" }
            );
        };

        return new Promise((resolve, reject) => {
            request.onsuccess = () => resolve();
            request.onerror = (event) => reject(event);
        });
    }

    private async _get(): Promise<InputFile[]> {
        if (typeof indexedDB === "undefined") return [];

        const db = await this._openDB();
        const transaction = db.transaction(
            [SavedManager.STORE_NAME], "readonly"
        );
        const store = transaction.objectStore(SavedManager.STORE_NAME);

        return new Promise((resolve, reject) => {
            const request = store.getAll();
            request.onsuccess = () => resolve(request.result);
            request.onerror = (event) => reject(event);
        });
    }

    private _openDB(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(SavedManager.DB_NAME, 1);
            request.onsuccess = (event: any) => resolve(event.target.result);
            request.onerror = (event) => reject(event);
        });
    }

    async getPending(): Promise<InputFile[]> {
        const data: InputFile[] = await this._get();
        return data.filter(image => TaskStatusType.isNotCompleted(image.status));
    }

    async getCompleted(): Promise<InputFile[]> {
        const data: InputFile[] = await this._get();
        return data.filter(image => TaskStatusType.isCompleted(image.status));
    }

    async add(image: InputFile): Promise<void> {
        const base64: string = await (
            image.file instanceof File ?
            FileConversion.toBase64(image.file) : image.file
        );

        const db = await this._openDB();
        const transaction = db.transaction(
            [SavedManager.STORE_NAME], "readwrite"
        );
        const store = transaction.objectStore(SavedManager.STORE_NAME);

        const request = store.get(image.taskId);
        request.onsuccess = (event: any) => {
            if (event.target.result) return store.put({
                ...event.target.result,
                ...image,
                file: base64,
            });
            store.add({
                ...image,
                file: base64,
            });
        };

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => resolve();
            transaction.onerror = (event) => reject(event);
        });
    }

    async remove(taskId: string): Promise<void> {
        const db = await this._openDB();
        const transaction = db.transaction(
            [SavedManager.STORE_NAME], "readwrite"
        );
        const store = transaction.objectStore(SavedManager.STORE_NAME);

        store.delete(taskId);

        return new Promise((resolve, reject) => {
            transaction.oncomplete = () => resolve();
            transaction.onerror = (event) => reject(event);
        });
    }
}

"use client";

import { ReactNode } from "react";
import { I18nextProvider } from "react-i18next";
import initTranslations from "@/app/i18n";
import { createInstance, i18n, Resource } from "i18next";

interface TranslationProviderProps {
    children: ReactNode;
    locale: string;
    namespaces: string[];
    resources?: Resource;
}

export default function TranslationProvider(props: TranslationProviderProps) {
    const i18n: i18n = createInstance();
    initTranslations(props.locale, props.namespaces, i18n, props.resources);
    return (
        <I18nextProvider i18n={i18n}>
            {props.children}
        </I18nextProvider>
    );
}

"use client";

import numeral from "numeral";
import Translations from "@/app/constants/translations";
import { useTranslation } from "react-i18next";

interface NumberFormatterProps {
    number: number;
}

export default function NumberFormatter(props: NumberFormatterProps) {
    const { t } = useTranslation(Translations.HEADER);
    const number: string = numeral(props.number).format("0.[0]a");

    return (
        <>
            {t(`${Translations.HEADER}:short-number`, { value: number })}
        </>
    );
}

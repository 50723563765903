"use client";

import Link from "next/link";
import { CurrencyDollarIcon } from "@heroicons/react/24/solid";
import { PlusIcon } from "@heroicons/react/24/solid";
import NumberFormatter from "@/app/[locale]/components/NumberFormatter";
import { getUserCredits } from "@/app/lib/database/credit";
import { useEffect, useState } from "react";

export default function HeaderUserInfo() {
    const [credits, setCredits] = useState<number>(0);

    useEffect(() => {
        getUserCredits().then(setCredits);
    }, []);

    return (
        <section className="flex flex-row gap-3">
            <Link 
                href="#pricing"
                className="group flex flex-row rounded-full gap-2 p-2
                bg-neutral-100 dark:bg-neutral-900 tour-element-2"
            >
                <section className="flex flex-row items-center gap-2">
                    <CurrencyDollarIcon
                        className="w-6 h-6 rounded-full bg-gradient-to-br
                        from-amber-400 to-amber-600 text-amber-300
                        dark:text-amber-300"
                    />
                    <span
                        className="text-sm text-neutral-950 
                        dark:text-neutral-50"
                    >
                        <NumberFormatter number={credits} />
                    </span>
                </section>
                <hr
                    className="border-s h-full border-neutral-200 
                    dark:border-neutral-800"
                />
                <section
                    className="rounded-full p-1 bg-gradient-to-br
                    from-amber-300 to-amber-500 
                    group-hover:brightness-90 dark:group-hover:brightness-110 
                    transition-all ease-in-out duration-300"
                >
                    <PlusIcon
                        className="w-4 h-4 text-neutral-950 
                        dark:text-neutral-50 group-hover:text-neutral-800
                        dark:group-hover:text-neutral-200 transition-all 
                        ease-in-out duration-300"
                    />
                </section>
            </Link>
        </section>
    );
}

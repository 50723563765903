"use client";

import dynamic from "next/dynamic";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FileManager, InputFile } from "@/app/lib/fileManager";
import SavedManager from "@/app/lib/savedManager";
import ImageFooter from "./ImageFooter";
import TaskStatusType from "@/app/constants/taskStatus";
import { addTask, getTaskResult, getTasksStatus, TasksStatus } from "@/app/lib/database/task";
import { RawTaskParams, TaskStatus } from "@/app/@types/TaskFile";

const ImageInput = dynamic(() => import("./ImageInput/ImageInput"));
const ImageContainer = dynamic(() => import("./ImagePreview/ImageContainer"));

export interface ImageInputProps {
    fileManager: FileManager;
    addFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export interface ImageContainerHandlerProps extends ImageInputProps {
    removeFile: (index: number) => void;
    downloadFile: (index: number) => void;
    selectFile: (index: number) => void;
    index: number;
};

type PromiseResult = PromiseFulfilledResult<Record<string, string>>

interface UserInfoProps {
    userCredits: number;
};

export default function ImageHandler(props: UserInfoProps) {
    // Global settings.
    const interval = useRef<NodeJS.Timeout>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [userCredits, setUserCredits] = useState<number>(props.userCredits);
    // File submission boolean and setInterval boolean.
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [verify, setVerify] = useState<boolean>(false);
    // FileManager instance, its reference and related.
    const [fileManager, setFileManager] = useState(new FileManager());
    const fileManagerRef = useRef<FileManager>(fileManager);
    const [index, setIndex] = useState<number>(fileManagerRef.current.length);

    const refreshFileManager = useCallback((files: InputFile[]) => {
        const _fileManager = new FileManager(files);
        const newIndex: number = fileManagerRef.current.index;
        _fileManager.index = newIndex;
        setFileManager(_fileManager);
        setIndex(newIndex);
    }, [fileManagerRef]);

    const selectFile = useCallback((index: number) => {
        fileManagerRef.current.index = index;
        refreshFileManager(fileManagerRef.current.files);
    }, [fileManagerRef, refreshFileManager]);

    const addFiles = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const files: File[] = Array.from(event.target.files)
            .filter(file => file.size <= 5_000_000);
        fileManagerRef.current.add(files);
        const numberFiles: number = fileManagerRef.current.length;
        fileManagerRef.current.index = numberFiles - 1;
        refreshFileManager(fileManagerRef.current.files);
    }, [fileManagerRef, refreshFileManager]);

    const removeFile = useCallback((index: number) => {
        fileManagerRef.current.remove(index);
        fileManagerRef.current.index = Math.max(0, index - 1);
        refreshFileManager(fileManagerRef.current.files);
    }, [fileManagerRef, refreshFileManager]);

    const downloadFile = useCallback((index: number) => {
        const file: InputFile = fileManagerRef.current.files[index];
        const link: HTMLAnchorElement = document.createElement("a");
        link.href = file.file as string;
        link.download = file.taskId;
        link.click();
    }, [fileManagerRef]);

    const updateTasksStatus = useCallback((reload: boolean = true) => {
        if (!submitted || verify) return;
        setVerify(true); // Prevent the interval from several executions.
        interval.current = setInterval(async () => {
            const stop = (): void => {
                clearInterval(interval.current);
                setVerify(false); // Interval can be called.
                setSubmitted(false); // No files are submitted.
            }

            // Prevent the refresh of the FileManager instance.
            let statusChanged: boolean = false;
            // Prevent the interval from running indefinitely.
            let stopInterval: boolean = true;
            const fileManager = fileManagerRef.current;
            const taskIds: string[] = fileManager.taskIds(reload);
            if (!taskIds.length) return stop();
            const status: TasksStatus = await getTasksStatus(taskIds);
            if (typeof status !== "object") return;
            fileManager.files.forEach(async (file) => {
                const taskId: string = file.taskId;
                // The file is not yet submitted, and has been added after 
                // the first submission of files.
                if (!Object.keys(status).includes(taskId)) {
                    if (file.status === TaskStatus.None) return;
                    if (file.status === TaskStatus.Completed) return;
                    file.status = TaskStatus.None;
                    statusChanged = true; // The FileManager must be refreshed.
                    return await fileManager.replace(file);
                }
                // A file is still pending, so the interval must continue.
                if (TaskStatusType.isQueued(file.status)) stopInterval = false;
                if (status[taskId] == file.status) return;
                file.status = status[taskId];
                statusChanged = true; // The FileManager must be refreshed.
                if (TaskStatusType.isCompleted(file.status)) {
                    file.file = await getTaskResult(taskId) ?? file.file;
                    if (file.status !== TaskStatus.Completed) return;
                    setUserCredits(Math.max(0, userCredits - 1));
                }
                await fileManager.replace(file);
            });
            // Stop the interval if all files have been processed.
            if (stopInterval) stop();
            if (statusChanged) refreshFileManager(fileManager.files);
        }, 5000);
    }, [refreshFileManager, submitted, userCredits, verify]);

    const submitFiles = useCallback(async () => {
        if (fileManagerRef.current.empty()) return;
        const fileManager: FileManager = fileManagerRef.current;
        const files: RawTaskParams[] = await fileManager.getFilesToUpload();
        if (!files.length) return;
        const taskIds: Record<string, string> = (
            await Promise.allSettled(files.map(async (file: RawTaskParams) => {
                const newTaskId: string | null = await addTask(file);
                if (!newTaskId) return null;
                return { [file.taskId]: newTaskId };
            }))
        )
        .filter((result): result is PromiseResult => {
            return result.status === 'fulfilled' && result.value !== null
        })
        .reduce((acc: Record<string, string>, result: PromiseResult) => {
            Object.assign(acc, result.value);
            return acc;
        }, {});
        Object.entries(taskIds).forEach(([fileTaskId, newTaskId]) => {
            fileManager.replaceTaskId(fileTaskId, newTaskId);
        });
        refreshFileManager(fileManagerRef.current.files);
        setSubmitted(true);
    }, [fileManagerRef, refreshFileManager]);

    useEffect(() => {
        if (loaded) return;
        setLoaded(true);
        const savedManager: SavedManager = new SavedManager();
        savedManager.getPending().then((files: InputFile[]) => {
            fileManagerRef.current = new FileManager(files);
            refreshFileManager(fileManagerRef.current.files);
            updateTasksStatus(true);
        });
    }, [loaded, fileManagerRef, refreshFileManager, updateTasksStatus]);

    useEffect(() => updateTasksStatus(), [updateTasksStatus]);

    return (
        <section
            className="flex flex-col w-full h-full p-4 gap-4 bg-neutral-100
            xl:rounded-t-xl dark:bg-neutral-900 items-center"
        >
            <section
                className="relative flex flex-col items-center justify-center 
                h-full rounded-xl overflow-hidden border-2 flex-1 w-full
                border-neutral-300 dark:border-neutral-700
                max-w-screen-xl"
            >
                {fileManagerRef.current.empty() && (
                    <ImageInput
                        fileManager={fileManagerRef.current}
                        addFiles={addFiles}
                    />
                )}
                {!fileManagerRef.current.empty() && (
                    <ImageContainer
                        fileManager={fileManagerRef.current}
                        addFiles={addFiles}
                        removeFile={removeFile}
                        downloadFile={downloadFile}
                        selectFile={selectFile}
                        index={index}
                    />
                )}
            </section>
            {(
                !fileManagerRef.current.empty() &&
                !TaskStatusType.isUploaded(
                    fileManagerRef.current.files[index].status
                )
            ) && (
                <ImageFooter
                    submitFiles={submitFiles}
                    numberFiles={fileManagerRef.current.lengthNotUploaded()}
                    userCredits={userCredits}
                />
            )}
        </section>
    );
}

"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function HeroSectionButtonText() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <span 
            className="text-lg text-neutral-50 font-bold 
            lg:text-xl"
        >
            {t(`${Translations.HOME}:hero.button.title`)}
        </span>
    );
}

import { TaskStatus } from "@/app/@types/TaskFile";

export default class TaskStatusType {
    private static readonly QUEUED: TaskStatus[] = [
        TaskStatus.Pending,
    ];
    private static readonly COMPLETED: TaskStatus[] = [
        TaskStatus.Completed,
    ];
    private static readonly UPLOADED: TaskStatus[] = [
        ...TaskStatusType.QUEUED,
        ...TaskStatusType.COMPLETED,
    ];
    private static readonly NOT_UPLOADED: TaskStatus[] = [
        TaskStatus.None,
        TaskStatus.Failed,
    ];
    private static readonly NOT_COMPLETED: TaskStatus[] = [
        ...TaskStatusType.QUEUED,
        ...TaskStatusType.NOT_UPLOADED,
    ];

    static isQueued(status: TaskStatus): boolean {
        return TaskStatusType.QUEUED.includes(status);
    }

    static isUploaded(status: TaskStatus): boolean {
        return TaskStatusType.UPLOADED.includes(status);
    }

    static isCompleted(status: TaskStatus): boolean {
        return TaskStatusType.COMPLETED.includes(status);
    }

    static isNotCompleted(status: TaskStatus): boolean {
        return TaskStatusType.NOT_COMPLETED.includes(status);
    }

    static isNotUploaded(status: TaskStatus): boolean {
        return TaskStatusType.NOT_UPLOADED.includes(status);
    }

}
export class RandomManager {

    private static readonly UUID_PATTERN: string = (
        "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    );

    static uuid(): string {
        return RandomManager.UUID_PATTERN.replace(/[xy]/g, (char: string) => {
            const random: number = Math.random() * 16 | 0;
            const value: number = char === "x" ? random : (random & 0x3 | 0x8);
            return value.toString(16);
        });
    }
}

"use client";

import { useTranslation } from "react-i18next";
import Translations from "@/app/constants/translations";

export default function PricingTitle() {
    const { t } = useTranslation(Translations.HOME);

    return (
        <section 
            className="flex flex-col items-center justify-center 
            gap-4 text-center"
        >
            <h2 
                className="flex flex-col font-bold text-3xl 
                text-neutral-950 md:text-4xl lg:leading-tight 
                lg:text-5xl dark:text-neutral-50"
            >
                {t(`${Translations.HOME}:pricing.title`)}
            </h2>
            <p 
                className="leading-relaxed text-balance text-base 
                text-neutral-700 md:text-lg lg:text-lg lg:font-medium 
                lg:w-2/3 dark:text-neutral-200"
            >
                {t(`${Translations.HOME}:pricing.description`)}
            </p>
        </section>
    );
}
